<template>
    <div class="accesslog">
        <div class="padd20 borbut20" style="position: relative;">
            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title">上线时间：</div>
                    <el-date-picker v-model="pickerTime" type="date" placeholder="选择日期" @change="selectPickerTime">
                    </el-date-picker>
                    <el-input v-model="uri_name" placeholder="请输入搜索标题" class="params" @keyup.enter.native="onSearch()"></el-input>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
        </div>
        <div class="TableList padd20">
            <div class="duoshao">
                <div class="title">访问日志</div>
                <!-- <div class="lvtotal">
                    总条数：{{lvtotal}} 条

                </div> -->
                <div class="lvtotal">总条数：{{lvtotal}} 条 <div class="lvtotal riri">
                        每页显示:
                        <el-select v-model="per_page" placeholder="10" @change="selectTrigger()" style="margin-left:20px;margin-right:20px;width:80px">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                        条
                    </div>
                </div>
            </div>
            <el-table ref="filterTable" :data="ErShopList" style="width: 100%">
                <el-table-column prop="app_name" label="项目名称"></el-table-column>
                <el-table-column prop="uri_name" label="页面标题"></el-table-column>
                <el-table-column prop="uri" label="页面地址"></el-table-column>
                <el-table-column prop="weblog_count" label="访问数量">
                </el-table-column>
                <el-table-column prop="your_kind_balance" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="onDetails(scope.row)" style="margin-left:0px; font-size:10px;padding: 7px 7px;">查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
        <!-- 查看详情 -->
        <el-dialog :title="uri_name" :visible.sync="confitionDialog" width="1000px" @close="onCancel">
            <div class="dialog-inside lubanshopedialog">
                <div class="Souxiangqing">
                    <el-date-picker v-model="detailsTime" type="date" placeholder="选择日期" @change="selectDetailsTime">
                    </el-date-picker>
                    <el-input v-model="params" placeholder="请输入搜索参数" class="params"></el-input>
                    <el-input v-model="phone" placeholder="请输入搜索手机号" class="params"></el-input>
                    <el-button type="primary" icon="el-icon-search" @click="onsearcher()">搜索</el-button>
                </div>
                <div class="duoshao">
                    <!-- <div class="title">{{uri_name}}</div> -->
                    <div class="lvtotal">
                        总条数：{{particulars.lvtotal}} 条
                    </div>
                </div>
                <el-table :data="particularsList" style="width: 100%">
                    <el-table-column prop="wechat_head" label="头像">
                        <template slot-scope="scope">
                            <span v-if="scope.row.wechat_user==null">--</span>
                            <img :src="scope.row.wechat_user.wechat_head" alt="" v-if="scope.row.wechat_user" class="img">
                        </template>
                    </el-table-column>
                    <el-table-column prop="wechat_name" label="名字">
                        <template slot-scope="scope">
                            <span v-if="scope.row.wechat_user==null">--</span>
                            <span v-if="scope.row.wechat_user"> {{ Base64.decode(scope.row.wechat_user.wechat_name)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="account" label="手机号">
                        <template slot-scope="scope">
                            <span v-if="scope.row.user==null">--</span>
                            <span v-if="scope.row.user">{{scope.row.user.account}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="client_ip" label="IP">
                    </el-table-column>
                    <el-table-column prop="params" label="数据参数">
                        <template slot-scope="scope">
                            <span v-if="scope.row.params==''">--</span>
                            <div v-if="scope.row.params!=''">
                                <div v-for="(item,key) in scope.row.params" :key="key" class="pan" v-if="scope.$index==key">
                                    <el-tooltip class="item" effect="dark" placement="top">
                                        <div slot="content">{{item.key}}<br />{{item.val}}</div>
                                        <span> {{item.key}} : {{item.val}}</span>
                                    </el-tooltip>

                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="lv-page" :page-size="particulars.per_page" :pager-count="11" @current-change="particularsChange" background layout="prev, pager, next" :total="particulars.lvtotal" hide-on-single-page :current-page.sync="particulars.paramPage"></el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import particulars from "./particulars.vue";
export default {
    data() {
        return {
            uri_name: "",
            paramData: {}, //表格参数
            shopid: "", //参数
            pickerTime: this.dateFtt("yyyy-MM-dd", new Date()), //日期参数
            per_page: 20, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1, //表格当前第几页
            shop_title: "",
            ErShopList: [], //数据
            particularsList: [], //详情数据
            confitionDialog: false,
            detailsTime: "", //详情日期
            particulars: {
                //详情分页
                per_page: 8, //一页多少条
                lvtotal: null, //共几条数据
                paramPage: 1, //表格当前第几页
            },
            detailsData: {}, //  详情数据
            params: "", //搜索参数
            particularsID: "",
            uri_name: "",
            phone: "",
            options: [
                {
                    value: 25,
                    label: "25",
                },
                {
                    value: 50,
                    label: "50",
                },
                {
                    value: 100,
                    label: "100",
                },
            ],
        };
    },

    mounted() {
        this.onDataList();
    },
    components: {
        particulars,
    },
    methods: {
        //显示条数改变
        selectTrigger() {
            this.paramPage = 1;
            this.paramData.pageSize = this.per_page;
            this.onDataList();
        },
        //详情分页
        onDetails(row) {
            this.detailsTime = this.dateFtt("yyyy-MM-dd", new Date());
            this.particularsID = row.id;
            this.uri_name = row.uri_name;
            this.onParticulars();
        },
        // 详情公用
        onParticulars() {
            this.particularsList = [];
            var param = this.detailsData;
            param.id = this.particularsID;
            param.pageSize = this.particulars.per_page;
            param.page = this.particulars.paramPage;
            this.$service.get(this.$api.getWeblogDetail, param, (res) => {
                if (res.code == "200") {
                    this.confitionDialog = true;
                    this.particularsList = res.data.data;
                    this.particulars.lvtotal = res.data.count;
                }
            });
        },
        // 取消
        onCancel() {
            this.confitionDialog = false;
            this.detailsData = {};
            this.detailsTime = "";
            this.particulars.paramPage = 1;
            this.phone = "";
        },
        selectPickerTime() {
            if (this.pickerTime != null) {
                this.pickerTime = this.dateFtt("yyyy-MM-dd", this.pickerTime);
            }
        },
        //页面搜索
        onSearch() {
            this.paramData = {
                date: this.pickerTime, //搜索日期
                uri_name: this.uri_name,
            };
            this.paramPage = 1;
            this.onDataList();
        },
        selectDetailsTime() {
            this.detailsTime = this.dateFtt("yyyy-MM-dd", this.detailsTime);
        },
        //详情搜索
        onsearcher() {
            this.detailsData = {
                date: this.detailsTime, //搜索日期
                params: this.params, //搜索参数
                phone: this.phone,
            };
            this.particulars.paramPage = 1;
            this.onParticulars();
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val;
            this.onDataList();
        },
        // 详情分页
        particularsChange(val) {
            this.particulars.paramPage = val;
            this.onParticulars();
        },
        //数据列表
        onDataList() {
            this.ErShopList = [];
            var param = this.paramData;
            param.page = this.paramPage;
            param.pageSize = this.per_page;
            this.$service.get(this.$api.getWeblogList, param, (res) => {
                if (res.code == "200") {
                    this.ErShopList = res.data.data;
                    this.lvtotal = res.data.count;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.accesslog {
    .lvtotal {
        line-height: 33px;
    }
    .img {
        width: 40px;
        height: 40px;
    }
    .Souxiangqing {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
    .pan {
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1 !important;
        overflow: hidden;
    }
}
</style>
<style lang="scss">
.accesslog {
    .el-dialog__header {
        background: #0075f7;
        padding-bottom: 15px;
    }
    .el-dialog__title {
        color: #fff;
    }
    .el-input__icon {
        line-height: 35px;
    }
    .params {
        width: 180px !important;
        margin-left: 20px;
    }
    .el-table td,
    .el-table th {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
.testtooltip {
    width: 200px;
}
</style>