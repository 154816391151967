<template>
    <div class="">

    </div>
</template>

<script>
export default {
    data() {
        return {
            confitionDialog: true,
        };
    },
    methods: {
        onCancel() {
            this.confitionDialog = false;
        },
    },

    mounted() {},

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>